




































































import { defineComponent, onMounted, PropType, reactive } from "@vue/composition-api";
import { Employee, RfidTag } from "@/types/apiEntities";
import { TagType } from '@/types/enums';
import useGlobalStorage from "@/composables/useGlobalStorage";
import { useEmployeeAPI } from "@/api-connectors/useEmployeeAPI";

export default defineComponent({
    name: "FormTag",
    props: {
        rfidTag: {
            type: Object as PropType<RfidTag>,
            required: true
        }
    },
    setup(props) {
        const { globalStore } = useGlobalStorage()
        const { getAllEmployeeRequest } = useEmployeeAPI()

        const updatedTag = reactive<RfidTag>(props.rfidTag)
        const employees = reactive<{ value: number | null; text: string; disabled?: boolean }[]>([])

        const getEmployees = async () => {
            const employeeList: Employee[] = (await getAllEmployeeRequest())?.data

            employees.splice(0)
            employees.push({ value: 0, text: 'Bitte wählen', disabled: true })
            employees.push({ value: null, text: 'Kein Mitarbeiter' })
            if (!employeeList) return;

            for (const employee of employeeList) {
                if (!employee.id) continue;
                const tempObject = { text: `${employee.firstName} ${employee.lastName}`, value: employee.id }
                employees.push(tempObject)
            }
        }

        onMounted(() => getEmployees())

        return {
            updatedTag,
            globalStore,
            // activeTag,
            TagType,
            employees
        }
    }
})
