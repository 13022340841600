


































































import { computed, defineComponent, onMounted, reactive, ref } from "@vue/composition-api";
import { modalController, useModal } from "@/composables/useModal";
import { useTable } from "@/composables/useTable";
import { TableChargerManagement } from "@/types/tableTypes";
import router from "@/router";
import { toastController } from "@/composables/useToast";
import { useTagAPI } from "@/api-connectors/useTagAPI";
import AddTag from "@/components/Modal/AddTag.vue";
import PaginationRow from "@/components/PaginationRow.vue";
import EditTag from "@/components/Modal/EditTag.vue";
import SearchbarTable from "@/components/Table/SearchbarTable.vue";

export default defineComponent({
    name: "ManagementRFIDTags",
    components: { SearchbarTable, EditTag, PaginationRow, AddTag },
    setup() {
        const { createMsgBoxConfirm } = useModal()
        const { updateTable, pageOptions } = useTable()
        const {
            deleteTagRequest,
            setTagRequest
        } = useTagAPI()

        const items = reactive<TableChargerManagement[]>([])

        const amountRows = ref(0)
        const perPage = ref(10)
        const currentPage = ref(1)
        const isBusy = ref(true)
        const selectedTag = ref('')
        const filter = ref('')
        const sortBy = ref('name')
        const sortDesc = ref(false)

        const fields = [
            { key: 'name', label: 'Name', sortable: true },
            { key: 'id', label: 'Tag ID', sortable: true },
            { key: 'active', label: 'Aktiv', sortable: true },
            { key: 'editId', label: '' }
        ]

        const currentCompany = computed(() => {
            return router.currentRoute.params.companyId;
        })

        const updateRFIDTable = async (page?: number) => {
            isBusy.value = true
            if (!page) page = currentPage.value;
            const offset = (page - 1) * perPage.value

            await updateTable({
                routePath: '/rfidTag/byCompany/' + currentCompany.value,
                targetList: items,
                targetMaxCount: amountRows,
                offset: offset,
                perPage: perPage.value,
                filter: filter.value,
            })

            isBusy.value = false
        }

        const checkDeleteTag = (tagId: string) => {
            createMsgBoxConfirm({
                header: 'Tag löschen',
                contentText: `Möchten Sie den Tag "${tagId}" wirklich entfernen?`,
                okHandler: () => deleteTag(tagId)
            })
        }

        const deleteTag = async (tagId: string) => {
            const response = await deleteTagRequest(tagId)

            if (!response) return;

            await updateRFIDTable()
            toastController.toast(`Der Tag wurde entfernt`, {
                title: 'Tag entfernt',
                autoHideDelay: 3000,
                variant: 'success',
                solid: true
            })

        }

        const setTag = async (event: Event, tagId: string, status: boolean) => {
            const response = await setTagRequest(tagId, status)

            if (!response) return;

            await updateRFIDTable()
            toastController?.toast(`Der Tag wurde ${status ? 'inaktiv' : 'aktiv'} gesetzt`, {
                title: 'Tag geändert',
                autoHideDelay: 3000,
                variant: 'success',
                solid: true
            })
        }

        const editTag = async (tagId: string) => {
            selectedTag.value = tagId
            modalController.show('edit-tag')
        }

        onMounted(() => updateRFIDTable(1))

        return {
            items,
            fields,
            pageOptions,
            perPage,
            currentPage,
            amountRows,
            isBusy,
            selectedTag,
            filter,
            sortBy,
            sortDesc,
            checkDeleteTag,
            setTag,
            updateRFIDTable,
            editTag
        }
    }
})
