












import { useTagAPI } from "@/api-connectors/useTagAPI";
import { defineComponent, reactive, watch } from "@vue/composition-api";
import { RfidTag } from "@/types/apiEntities";
import { toastController } from "@/composables/useToast";
import { modalController } from "@/composables/useModal";
import FormTag from "@/components/Modal/Subcomponents/FormTag.vue";
import { emptyObjects } from "@/types/emptyObjects";

export default defineComponent({
    name: "EditTag",
    components: { FormTag },
    props: {
        tagId: String
    },
    setup(props, { emit }) {
        const { editTagRequest, getTagRequest } = useTagAPI()
        const { emptyRfidTag } = emptyObjects()

        const rfidTag = reactive<RfidTag>(emptyRfidTag())

        const cleanRequestBody = async () => {
            const tempObject: RfidTag = { ...rfidTag }
            if (!tempObject.employee?.id) tempObject.employee = null
            return tempObject;
        }

        const editTag = async () => {
            const apiObject = await cleanRequestBody()

            const response = await editTagRequest(props.tagId ?? '', apiObject)

            if (!response) return;

            toastController.toast(`Der Tag wurde bearbeitet`, {
                title: 'Tag bearbeitet',
                autoHideDelay: 3000,
                variant: 'success',
                solid: true
            })
            emit('update-table')

            clearTagForm()

            modalController.hide('edit-tag')
        }

        const clearTagForm = () => {
            Object.assign(rfidTag, emptyRfidTag())
            emit('reset-id')
        }

        const updateTag = (updatedTag: RfidTag) => {
            Object.assign(rfidTag, updatedTag)
        }

        const getCurrentTag = async () => {
            if (props.tagId === '0') return;
            if (!props.tagId) return Object.assign(rfidTag, emptyRfidTag());
            const response = await getTagRequest(props.tagId)

            if (!response) return;

            if (!response.data.employee) response.data.employee = { id: 0 }

            Object.assign(rfidTag, response.data)
        }

        watch(props, () => getCurrentTag())

        return {
            rfidTag,
            editTag,
            clearTagForm,
            updateTag
        }
    }
})
