













import { defineComponent, reactive } from "@vue/composition-api";
import useGlobalStorage from "@/composables/useGlobalStorage";
import { useTagAPI } from "@/api-connectors/useTagAPI";
import { toastController } from "@/composables/useToast";
import { modalController } from "@/composables/useModal";
import FormTag from "@/components/Modal/Subcomponents/FormTag.vue";
import { RfidTag } from "@/types/apiEntities";
import { emptyObjects } from "@/types/emptyObjects";

export default defineComponent({
    name: "AddTag",
    components: { FormTag },
    setup(_, { emit }) {
        const { globalStore } = useGlobalStorage()
        const { addTagRequest } = useTagAPI()
        const { emptyRfidTag } = emptyObjects()

        const rfidTag = reactive<RfidTag>(emptyRfidTag())

        const addTag = async () => {
            rfidTag.company.id = Number(globalStore.currentCompany.id)

            //replaces all ":" in theRfid Tag
            rfidTag.id = rfidTag.id.replace(new RegExp(':', 'g'), '');

            const response = await addTagRequest(rfidTag)

            if (!response) return;

            toastController.toast(`Der Tag wurde hinzugefügt`, {
                title: 'Tag hinzugefügt',
                autoHideDelay: 3000,
                variant: 'success',
                solid: true
            })

            emit('update-table')

            modalController.hide('add-tag')
        }

        const clearTagForm = () => {
            Object.assign(rfidTag, emptyRfidTag())
        }

        const updateTag = (updatedTag: RfidTag) => {
            Object.assign(rfidTag, updatedTag)
        }

        return {
            rfidTag,
            globalStore,
            addTag,
            clearTagForm,
            updateTag
        }
    }
})
